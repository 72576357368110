import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setMobileList } from 'redux/index';
import { excludeType } from 'helpers';
import { Add } from '@material-ui/icons';
import { OptionMenu, DashboardToggle, NavBack, RoundButton } from 'components';
import styles from '../userList.module.css';

export default function ListLabel({
	label,
	addToggle,
	addHover,
	menuItems,
	isMd,
	inviteItems,
}) {
	const dispatch = useDispatch();
	const { push } = useHistory();
	const showNavOption = (excludeType(['child']) || label === 'Support') && isMd;
	const { user } = useSelector(({ user }) => user);

	const plusButtonStyle = {
		width: '58px',
		height: '58px',
		iconSize: '40px',
	};

	const plusButtonPosition = {
		position: 'absolute',
		right: '10px',
	};

	const invitePlusButtonPosition = {
		width: '100%',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		display: 'flex',
		flex: 1,
	};

	const inviteOptions = [
		{
			value: 'single',
			label: 'Single Invite',
			onClick: () => {
				dispatch(setMobileList(false));
				addToggle();
			},
		},
		{
			value: 'bulk',
			label: 'Bulk Invite',
			onClick: () => push({ pathname: '/invite' }),
		},
	];

	if (inviteItems && user.role === 'admin') {
		return (
			<div className={styles.rootInvite}>
				{showNavOption &&
					(user?.id ? (
						<DashboardToggle className={styles.listLabelDashToggle} />
					) : (
						<NavBack
							onClick={() => {
								dispatch(setMobileList(false));
								push('/login/');
							}}
							className={styles.listLabelDashToggle}
						/>
					))}
				{label}
			{/*	<OptionMenu
					style={invitePlusButtonPosition}
					menuItems={inviteOptions}
					inviteItems={inviteItems}
					onClick={() => console.log('on click2')}
					divButton>
					*/}
					<RoundButton
						Icon={Add}
						style={{ ...plusButtonStyle }}
						hoverText={addHover}
						buttonClass='list-add-button'
						onClick={() => {
						dispatch(setMobileList(false));
						addToggle();
						}}
					/>
			{/*	</OptionMenu> */}
			</div>
		);
	}

	return (
		<div className={styles.root}>
			{showNavOption &&
				(user?.id ? (
					<DashboardToggle className={styles.listLabelDashToggle} />
				) : (
					<NavBack
						onClick={() => {
							dispatch(setMobileList(false));
							push('/login/');
						}}
						className={styles.listLabelDashToggle}
					/>
				))}
			{label}
			{addHover ? (
				addToggle ? (
					<RoundButton
						Icon={Add}
						buttonClass='list-add-button'
						style={{ ...plusButtonStyle, ...plusButtonPosition }}
						onClick={() => {
							dispatch(setMobileList(false));
							addToggle();
						}}
						hoverText={addHover}
					/>
				) : menuItems ? (
					<OptionMenu
						style={plusButtonPosition}
						menuItems={menuItems}
						divButton>
						<RoundButton
							Icon={Add}
							style={plusButtonStyle}
							hoverText={addHover}
						/>
					</OptionMenu>
				) : null
			) : null}
		</div>
	);
}
